var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-4"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"accent--text"},[_vm._v("Liste des événements")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Date")]),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.valueLabel))]),_c('th',{staticClass:"text-right"},[_vm._v("Risque Financier")])])]),_c('tbody',[_c('tr',{staticClass:"font-weight-medium"},[_c('td',[_vm._v(_vm._s(_vm.alertData.formatted.date))]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.alertData.formatted.value))]),(_vm.alertData.formatted.value_threshold)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0, 0, 0, 0.9)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,false,947856536)},[_c('span',[_vm._v("Risque max accepté :")]),_vm._v(" "+_vm._s(_vm.alertData.formatted.value_threshold)+" ")]):_vm._e()],1),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.alertData.formatted.financial_risk)+" ")])]),_vm._l((_vm.alertData.previous),function(previous,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(previous.formatted.date)+" ")]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(previous.formatted.value))]),(previous.formatted.value_threshold)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0, 0, 0, 0.9)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Risque max accepté :")]),_vm._v(" "+_vm._s(previous.formatted.value_threshold)+" ")]):_vm._e()],1),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(previous.formatted.financial_risk)+" ")])])}),(_vm.similarAlerts.length > 0)?_c('div',{staticClass:"mt-4 mb-1 ml-6 text--secondary font-weight-regular font-italic"},[_vm._v(" Hors période d'analyse : ")]):_vm._e(),_vm._l((_vm.similarAlerts),function(sim){return _c('tr',{key:sim.id},[_c('td',[_vm._v(_vm._s(sim.formatted.date))]),_c('td',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(sim.formatted.value))]),(sim.formatted.value_threshold)?_c('v-tooltip',{attrs:{"bottom":"","color":"rgba(0, 0, 0, 0.9)"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Risque max accepté :")]),_vm._v(" "+_vm._s(sim.formatted.value_threshold)+" ")]):_vm._e()],1),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(sim.formatted.financial_risk))])])})],2)]},proxy:true}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }