<template>
  <v-card class="mb-4">
    <v-card-text>
      <v-row>
        <v-col>
          <h2 class="accent--text">Liste des événements</h2>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Date</th>
                  <th class="text-center">{{ valueLabel }}</th>
                  <th class="text-right">Risque Financier</th>
                </tr>
              </thead>
              <tbody>
                <!-- ------- -->
                <!-- CURRENT -->
                <!-- ------- -->
                <tr class="font-weight-medium">
                  <td>{{ alertData.formatted.date }}</td>
                  <td class="text-center">
                    <span>{{ alertData.formatted.value }}</span>
                    <v-tooltip
                      v-if="alertData.formatted.value_threshold"
                      bottom
                      color="rgba(0, 0, 0, 0.9)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon right v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>Risque max accepté :</span>
                      {{ alertData.formatted.value_threshold }}
                    </v-tooltip>
                  </td>
                  <td class="text-right">
                    {{ alertData.formatted.financial_risk }}
                  </td>
                </tr>

                <!-- ------ -->
                <!-- EVENTS -->
                <!-- ------ -->
                <tr v-for="(previous, i) in alertData.previous" :key="i">
                  <td>
                    {{ previous.formatted.date }}
                  </td>
                  <td class="text-center">
                    <span>{{ previous.formatted.value }}</span>
                    <v-tooltip
                      v-if="previous.formatted.value_threshold"
                      bottom
                      color="rgba(0, 0, 0, 0.9)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon right v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>Risque max accepté :</span>
                      {{ previous.formatted.value_threshold }}
                    </v-tooltip>
                  </td>
                  <td class="text-right">
                    {{ previous.formatted.financial_risk }}
                  </td>
                </tr>

                <!-- ------------- -->
                <!-- OUT OF PERIOD -->
                <!-- ------------- -->
                <div
                  v-if="similarAlerts.length > 0"
                  class="mt-4 mb-1 ml-6 text--secondary font-weight-regular font-italic"
                >
                  Hors période d'analyse :
                </div>
                <tr v-for="sim in similarAlerts" :key="sim.id">
                  <td>{{ sim.formatted.date }}</td>
                  <td class="text-center">
                    <span>{{ sim.formatted.value }}</span>
                    <v-tooltip
                      v-if="sim.formatted.value_threshold"
                      bottom
                      color="rgba(0, 0, 0, 0.9)"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon right v-bind="attrs" v-on="on">
                          mdi-information
                        </v-icon>
                      </template>
                      <span>Risque max accepté :</span>
                      {{ sim.formatted.value_threshold }}
                    </v-tooltip>
                  </td>
                  <td class="text-right">{{ sim.formatted.financial_risk }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { formatAlerts } from "@/services/alert";
import { getKeyLabel } from "@/services/rule";

export default {
  props: {
    alertData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      similarAlerts: (state) =>
        formatAlerts(state.alerts.similarAlerts, {
          queries: state.rules.rulesNames,
        }),
    }),

    valueLabel() {
      return getKeyLabel("value", { rule: this.alertData.rule }) || this.$t("value");
    },
  },
  created() {
    const alert = this.alertData;
    const lastPreviousAlertDate = alert.previous.slice(-1)[0].date;

    this.$store.dispatch("alerts/getSimilarAlerts", {
      key: alert.key,
      ruleId: alert.rule_id,
      duration: alert.triggered_duration == 30 ? 7 : 2,
      latestDate: lastPreviousAlertDate,
    });
  },
};
</script>
